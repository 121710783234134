import React from 'react';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Intro from './Intro';
import './styles.scss';
import Items from "./Items";

const useStyles = makeStyles(theme => ({
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
}));

export default function Services({name, tagLine}) {
    const classes = useStyles();
    return (
        <main>
            <Intro name={name} tagLine={tagLine}/>
            <Container className={classes.cardGrid} maxWidth="md">
                <Grid container spacing={4}>
                    <Items/>
                </Grid>
            </Container>
        </main>
    );
}