import React from 'react';
import {graphql} from 'gatsby';
import Services from "../components/Services/index";
import MajistiLogo from '../theme/majisti-logo.svg';
import {makeStyles, Theme} from "@material-ui/core/styles";
import {AppBar, Toolbar, Typography} from "@material-ui/core";

interface IndexPageData {
    site: {
        siteMetadata: {
            name: string;
            tagline: string;
        },
    },
}

export const indexPageQuery = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        name
        tagline
      }
    }
  }
`;

const useStyles = makeStyles((theme:Theme) => ({
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
}));

export default function index({data}) {
    const {name, tagline} = data.site.siteMetadata;
    const classes = useStyles();

    return (
        <React.Fragment>
            {/* todo: move App bar somewhere we can reuse */}
            <AppBar position="relative">
                <Toolbar>
                    <img src={MajistiLogo} className="logo" alt="Majisti Logo" width="60" height="60"/>
                    <Typography variant="h6" color="inherit" noWrap>
                        {name}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Services name={name} tagLine={tagline} />
            {/* todo: move footer bar somewhere we can reuse */}
            <footer className={classes.footer}>
                <Typography variant="h6" align="center" gutterBottom>
                    Majisti's portal
                </Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                    Remember that releases always take time.
                </Typography>
            </footer>
        </React.Fragment>
    );
}
