import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {Grid} from "@material-ui/core";
import {graphql, useStaticQuery} from "gatsby";
import Img from 'gatsby-image'

const cards = [
    {
        id: 'confluence',
        name: 'Confluence',
        description: 'Enterprise Wiki',
        url: 'http://documentation.majisti.com',
    },
    {
        id: 'jira',
        name: 'Jira',
        description: 'Issue Tracker',
        url: 'http://issues.majisti.com',
    },
    {
        id: 'jenkins',
        name: 'Jenkins',
        description: 'Continuous Integration Server',
        url: 'http://ci.majisti.com',
    },
    {
        id: 'bitbucket',
        name: 'Bitbucket',
        description: 'Git repositories',
        url: 'https://bitbucket.org/majisti',
    },
    {
        id: 'upsource',
        name: 'UpSource',
        description: 'Code review tool',
        url: 'http://reviews.majisti.com',
    },
    {
        id: 'nas',
        name: 'NAS',
        description: `Majisti's NAS`,
        url: 'http://office.majisti.com:5000',
    },
    {
        id: 'majisti-website',
        name: `Majisti's Website`,
        description: `majisti.com`,
        url: 'http://majisti.com',
    },
    {
        id: 'cassandra',
        name: `Cassandra`,
        description: `Majisti Backups`,
        url: 'http://backups.majisti.com',
    },
    {
        id: 'infinite-wp',
        name: 'Infinite Wordpress',
        description: `Manage all Majisti's Wordpress installations in one place`,
        url: 'http://wordpress-admin.majisti.com',
    },
    {
        id: 'rl-custom-training',
        name: 'Rocket League Training',
        description: `Custom training codes for Rocket League`,
        url: 'http://rlcustomtraining.com',
    },
    {
        id: 'satis',
        name: `Composer Private Registry`,
        description: `Private packages at Majisti`,
        url: 'http://packages.majisti.com',
    },
    {
        id: 'album',
        name: `Majisti's Album`,
        description: `Good old flyer that we were selling for cheap websites.`,
        url: 'http://album.majisti.com',
    },
    {
        id: 'b2bup',
        name: `B2Bup`,
        description: `Serious Business. Seriously.`,
        url: 'http://demo.b2bup.com',
    },
    {
        id: 'scratchcard',
        name: `Majisti's Scratchcard`,
        description: `A prototype using canvas in order to simulate scratching a card to reveal rebates`,
        url: 'http://scratchcard.majisti.com',
    },
    {
        id: 'boob-or-guru',
        name: `Booborguru`,
        description: `BoobOrGuru is a blog created by members of EventZen and Majisti to share and discuss concepts and ideas.`,
        url: 'http://booborguru.com',
    },
    {
        id: 'docker-hub',
        name: `Majisti's Docker Hub`,
        description: `Majisti Docker Hub`,
        url: 'https://hub.docker.com/u/majisti',
    }
];

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
}));

const Items = () => {
    const classes = useStyles();

    const images = useStaticQuery(
        graphql`
        {
          allFile(filter: { sourceInstanceName: { eq: "services" } }) {
            edges {
                node {
                  name,
                  extension,
                  publicURL,
                  childImageSharp {
                    # Specify the image processing specifications right in the query.
                    fixed(height: 150) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
            }
          }
        }
    `);
    return <React.Fragment>
        {
            cards.map(card => (
                <Grid item key={card.id} xs={12} sm={6} md={4}>
                    <Card className={classes.card}>
                        {images.allFile.edges.filter(edge => edge.node.name === card.id).map(edge => {
                            if (!edge.node.childImageSharp && edge.node.extension === 'svg') {
                                return <img
                                    key={edge.node.name}
                                    src={edge.node.publicURL}
                                    alt={edge.node.name}
                                />
                            }

                            return <Img
                                key={edge.node.name}
                                alt={edge.node.name}
                                fixed={edge.node.childImageSharp.fixed}
                                style={{margin: '5px auto 0 auto'}}
                            />
                        })}
                        <CardContent className={classes.cardContent}>
                            <Typography gutterBottom variant="h5" component="h2">
                                {card.name}
                            </Typography>
                            <Typography>
                                {card.description}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" color="secondary" href={card.url} target="_blank">
                                View
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            ))
        }
    </React.Fragment>
};

export default Items